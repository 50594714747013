<template>
  <v-footer
    id="pages-core-footer"
    absolute
    color="light-blue lighten-5 primary--text"
    dark
  >
    <v-container>
      <v-row
        align="center"
        no-gutters
        class="justify-center"
      >
        <v-col
          v-for="(link, i) in links"
          :key="i"
          class="text-center"
          cols="6"
          md="auto"
        >
          <a
            :href="link.href"
            class="mr-0 primary--text"
            rel="noopener"
            v-text="link.text"
          />
        </v-col>

        <v-spacer class="hidden-sm-and-down" />

        <v-col
          cols="12"
          md="auto"
        >
          <div class="text-body-1 font-weight-light pt-6 pt-md-0 text-center primary--text">
            &copy; {{ this.currentYear }}, {{ $t('common.copyRight') }}
            Blanket.com {{ siteVersion() }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  import moment from 'moment'
  import { siteVersion } from '../../../../i18n'
  export default {
    name: 'PagesCoreFooter',

    data: () => ({
      links: [
        {
          href: '/about/team',
          text: '',
        },
      ],
      currentYear: moment().format('YYYY')
    }),
    created () {
      this.links[0].text = this.$t('menu.aboutUs')
    },
    methods: {
      siteVersion () {
        return siteVersion
      }
    }
  }
</script>

<style lang="sass">
  #pages-core-footer
    a
      color: #FFFFFF
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
